.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0.8rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-container {
  width: 100%;
  max-height: 100% !important;
  overflow-y: auto;
  background-color: #fff;
  padding: 20px 40px;
  position: relative;
  overflow-x: hidden !important;
  padding-top: 3rem;
}

h2 {
  text-align: center;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
  background-color: white;
  margin-bottom: 0 !important;
  padding: 2rem;
  text-align: center;
  justify-content: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  color: #555;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  font-size: 14px;
  background-color: #f9f9f9;
  color: #333;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, background-color 0.3s;
  
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #007bff;
  background-color: #fff;
}

.form-group select {
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23777" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
}

textarea {
  resize: none;
  min-height: 100px;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submit-button,
.close-button {
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button {
  background-color: #fbe67b;
  color: black;
}

.submit-button:hover {
  background-color: #f0d456;
}

.close-button {
  background-color: #007bff;
  color: #fff;
}

.close-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group {
  width: 100%;
}
.close-icon {
  position: absolute;
  top: 0.2rem;
  right: 1rem;
  z-index: 9999999999 !important;
  svg {
    width: 1.8rem;
  }
}
.apply-form-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.upload-button-container {
  padding: 1rem 0;
}
.modal-header {
  position: fixed !important;
  height: 7vh !important;
  background-color: white !important;
  width: 100% !important;
  z-index: 999999999 !important;
  top: 0 !important;
  left: 0 !important;
  justify-content: center !important;
  overflow: hidden !important;
}


@media screen and (min-width: 290px) and (max-width: 700px) {
  .modal-overlay {
    width: 95%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal-header {
    height: 7vh;
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 99999;
    top: 0;
    overflow: hidden;
    text-align: center;
    justify-content: center !important;
    padding-bottom: 3rem;
    border-radius: 20px 20px 0 0;
  }
  .modal-container {
    padding-top: 5rem;
  }
  .close-icon {
    position: absolute;
    top: 0.2rem;
    right: 0.7rem;
    z-index: 100000;
    svg {
      width: 1.4rem;
    }
  }
}

@media screen and (min-width: 650px) and (max-width: 1024px) {
  .modal-overlay {
    width: 85%;
    height: 80%;
  }
  .modal-container {
    padding-top: 4rem !important;
    padding: 1rem;
  }
}
