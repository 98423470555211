@import url("https://fonts.googleapis.com/css2?family=Monoton&family=Poppins:wght@300;400;500;800&display=swap");
body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*  Scroll bar Css */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #74a9e9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

svg.social-media-icon {
  transition-duration: 500ms;
  &:hover {
    transform: scale(1.5);
    transition-duration: 500ms;
  }
}
.uppercase {
  text-transform: uppercase;
}
