@import "../colorvariables.scss";

.about {
  width: 100%;
  height: 100vh;
  padding-top: 13vh;
  position: relative;
  background-image: url(../assets/AboutBg.png);
  .about-deco-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 450px;
    background-color: $secondary-color;
  }
  .about-container {
    height: 65%;
    // background-color: yellow;
    width: 100%;
    .about-image-container {
      width: 40%;
      display: inline-block;
      position: relative;
      text-align: end;
      height: 100%;
      .aboutus-image {
        // width: 75%;
        width: 90%;
        height: 80%;
        border-radius: 50%;
        margin-top: 3rem;
        // border: 15px solid $yellow-color;
      }
    }
    .about-description-container {
      width: 50%;
      display: inline-block;
      height: 100%;
      vertical-align: top;
      padding-left: 3rem;
      padding-top: 3rem;
      text-align: justify;
      .about-descriptions-content {
        padding-top: 1rem;
      }
    }
    .about-vertical-deco-container {
      width: 5%;
      background-color: $primary-color;
      display: inline-block;
      position: absolute;
      right: 0px;
      height: 100%;
    }
    .about-our-culture {
      width: 80%;
      margin: auto;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1600px) {
  .about {
    height: unset;
    padding-top: 7rem;
    .about-container {
      .about-description-container {
        padding-left: 1rem;
        padding-top: 2rem;
      }
      .about-our-culture {
        width: 85%;
      }
    }
    .about-deco-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 30%;
      height: 75%;
      background-color: #0354a6;
    }
  }
}
@media only screen and (min-width: 290px) and (max-width: 600px) {
  .about {
    width: 100%;
    padding-top: 5rem;
    height: unset;
    position: relative;
    background-image: url(../assets/AboutBg.png);
    .about-deco-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 60%;
      height: 300px;
      background-color: $secondary-color;
    }
    .about-container {
      height: 65%;
      // background-color: yellow;
      width: 100%;
      .about-image-container {
        width: 100%;
        display: inline-block;
        text-align: center;

        .aboutus-image {
          // width: 18rem;
          // height: 18rem;
          // border: 15px solid $yellow-color;
          // margin: auto;
          // position: relative;
          // top: 2rem;
          width: 21rem;
          height: 20rem;
          border: unset;
          margin: auto;
          position: relative;
          top: 2rem;
        }
      }
      .about-description-container {
        width: calc(100% - 30px);
        display: block;
        padding-left: 0;
        padding-top: 4rem;
        text-align: justify;
        margin: auto;
        .about-descriptions-content {
          padding-top: 1rem;
          .about-descriptions-text {
            font-size: 14px;
          }
        }
      }
      .about-vertical-deco-container {
        display: none;
      }
      .about-our-culture {
        width: calc(100% - 30px);
        display: block;
        padding-left: 0;
        padding-top: 1rem;
        text-align: justify;
        margin: auto;
        .about-descriptions-our-culture {
          padding-top: 1rem;
          .about-descriptions-text {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) and (orientation: landscape) {
  .about {
    width: 100%;
    height: 100vh;
    padding-top: 13vh;
    position: relative;
    background-image: url(../assets/AboutBg.png);
    .about-deco-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 30%;
      height: 60%;
      background-color: #0354a6;
    }
    .about-container {
      height: 65%;
      // background-color: yellow;
      width: 100%;
      .about-image-container {
        width: 49%;
        display: inline-block;
        position: relative;
        text-align: end;
        height: 100%;
        .aboutus-image {
          // width: 75%;
          width: 100%;
          height: 500px;
          border-radius: 50%;
          margin-top: 1rem;
          // border: 15px solid $yellow-color;
        }
      }
      .about-description-container {
        width: 43%;
        display: inline-block;
        height: 100%;
        vertical-align: top;
        padding-left: 1rem;
        padding-top: 1rem;
        text-align: justify;
        .about-descriptions-content {
          padding-top: 1rem;
        }
      }
      .about-vertical-deco-container {
        width: 5%;
        background-color: $primary-color;
        display: inline-block;
        position: absolute;
        right: 0px;
        height: 100%;
      }
      .about-our-culture {
        width: 80%;
        margin: auto;
      }
      .about-descriptions-text {
        font-size: 13px;
      }
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 1600px) {
    .about {
      height: unset;
      padding-top: 3rem;
      .about-container {
        .about-description-container {
          padding-left: 1rem;
          padding-top: 2rem;
        }
        .about-our-culture {
          width: 85%;
        }
      }
      .about-deco-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 30%;
        height: 73%;
        background-color: #0354a6;
      }
    }
  }
}
