.acknowledge-container {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 40px;
}

.acknowledge-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: 500;
  font-size: 1.8rem;
  padding: 1rem 0;
  width: 80%;
  padding-left: 1.5rem;
  .acknowledge-decorator {
    color: #0354a6;
    font-size: 3rem;
    width: max-content;
    height: 3.5rem;
    padding-left: 3rem;
  }
}

.acknowledge-img {
  display: flex;
  justify-content: center;
  margin-left: 30px;
  width: 20%;
}

.acknowledge-img img {
  width: 120px;
  height: auto;
}

@media only screen and (min-width: 290px) and (max-width: 600px) {
  .acknowledge-container {
    flex-direction: column;
    padding: 20px;
    align-items: center;
  }

  .acknowledge-details {
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    padding-left: 0;
    .acknowledge-decorator {
      font-size: 3rem;
      width: max-content;
      padding-left: 0rem;
    }
  }

  .acknowledge-img {
    margin-left: 0;
    margin-top: 20px;
  }

  .acknowledge-img img {
    width: 100px;
  }
}
