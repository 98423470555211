.privacy {
  width: 100%;
  height: 100%;
}
.title {
  display: flex;
  padding-top: 11%;
  justify-content: center;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
  font-size: 3.75rem;
  font-weight: 700;
}
.note {
  width: 97%;
  font-size: 1rem;
  padding-left: 5%;
  font-weight: 100;
  padding-top: 5%;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
}
.wwr {
  padding-left: 5%;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.563rem;
  font-weight: 700;
  padding-top: 3%;
}
.wwrc {
  padding-top: 1%;
  padding-left: 5%;
  font-weight: 100;
  font-size: 1rem;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
}
.info-col {
  padding-left: 5%;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.563rem;
  font-weight: 700;
  padding-top: 1%;
}
.info-col-con1 {
  padding-top: 1%;
  padding-left: 5%;
  padding-left: 5%;
  font-weight: 100;
  font-size: 1rem;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
}
.info-col-tl1 {
  padding-left: 5%;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.1rem;
  font-weight: 700;
  padding-top: 1%;
}
.info-col-points {
  padding-top: 1%;
  // padding-left: 1%;
}
.arrow {
  padding-right: 1%;
}
.points-text {
  font-size: 1rem;
  padding-left: 8%;
}
.info-col-tl2 {
  padding-left: 5%;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.1rem;
  font-weight: 700;
  padding-top: 1%;
}
.info-col-ps {
  font-size: 1rem;
  padding-left: 8%;
  font-weight: 400;
}

.points-text_ps {
  font-size: 1rem;
  padding-left: 10%;
}
.hl-tl {
  padding-left: 5%;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.563rem;
  font-weight: 700;
  padding-top: 1%;
}
.hl-con {
  padding-top: 1%;
  padding-left: 5%;
  padding-left: 5%;
  font-weight: 100;
  font-size: 1rem;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
}
.hl-con-ls1 {
  padding-top: 1%;
  padding-left: 5%;
  padding-left: 5%;
  font-weight: 100;
  font-size: 1rem;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
}
.hl-con-ls2 {
  padding-top: 1%;
  padding-left: 5%;
  padding-left: 5%;
  font-weight: 100;
  font-size: 1rem;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
}
.hl-con-ls3 {
  padding-top: 1%;
  padding-left: 5%;
  padding-left: 5%;
  font-weight: 100;
  font-size: 1rem;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
}
.hl-con-ls4 {
  padding-top: 1%;
  padding-left: 5%;
  padding-left: 5%;
  font-weight: 100;
  font-size: 1rem;
  font-family: DM Sans, sans-serif;
  -webkit-tap-highlight-color: transparent;
}
.privacy-policy {
  color: #ffe0a8;
  text-decoration: none !important;
}
.privacy-policy:hover {
  color: #ffe0a8 !important;
}
