@import "../colorvariables.scss";

.footer {
  width: 100%;
  height: 60vh;
  background-image: url("../assets/footer.png");
  .footer-container {
    height: 100%;
    color: $white-color;
    padding-top: 5rem;
    padding-bottom: 5rem;
    width: 80%;
    margin: auto;
    .pen-details {
      // width: 30%;
      width: 40%;

      display: inline-block;
      height: 100%;
      .pen-header {
        font-size: 32px;
        font-weight: 900;
        padding-bottom: 1rem;
      }
      .pen-title {
        padding-bottom: 15px;
        font-size: 18px;
      }
      .pen-description {
        width: calc(100% - 95px);
        font-size: 18px;
      }
      .social-media-hendles {
        padding-top: 2rem;
        .pen-social-media {
          cursor: pointer;
          list-style: none;
          display: inline-block;
        }
      }
    }
    .pen-site-details {
      width: 30%;
      display: inline-block;
      height: 100%;
      vertical-align: top;
      .site-detail {
        font-size: 28px;
        font-weight: bold;
      }
      .pages-list {
        padding-top: 1rem;
        li {
          list-style: none;
          font-size: 18px;
          padding-bottom: 0.4em;
          color: $yellow-color;
          cursor: pointer;
          width: max-content;
        }
      }
    }
    .pen-contact-details {
      width: 30%;
      vertical-align: top;
      display: inline-block;
      height: 100%;
      .contact-header {
        font-size: 28px;
        font-weight: bold;
      }
      .pages-list {
        padding-top: 1rem;
        text-wrap: wrap;
        li { 
          display: flex;
          list-style: none;
          font-size: 18px;
          padding-bottom: 1rem;
          color: $white-color;
          .mail-icon {
            padding-right: 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 290px) and (max-width: 600px) {
  .footer {
    height: auto;
    .footer-container {
      padding-top: 3rem;
      padding-bottom: 3rem;
      width: 80%;
      margin: auto;
      .pen-details {
        width: 100%;
        display: block;
        .pen-header {
          font-size: 32px;
          font-weight: 900;
        }
        .pen-title {
          padding-bottom: 15px;
          font-size: 18px;
        }
        .pen-description {
          width: 100%;
          font-size: 18px;
        }
        .social-media-hendles {
          padding-top: 2rem;
          .pen-social-media {
            cursor: pointer;
            list-style: none;
            display: inline-block;
          }
        }
      }
      .pen-site-details {
        width: 100%;
        display: inline-block;
        padding-bottom: 1rem;
        .site-detail {
          font-size: 28px;
          font-weight: bold;
        }
        .pages-list {
          padding-top: 1rem;
          li {
            list-style: none;
            font-size: 18px;
            padding-bottom: 0.4em;
            width: max-content;
          }
        }
      }
      .pen-contact-details {
        width: 100%;
        display: block;
        .contact-header {
          font-size: 28px;
          font-weight: bold;
        }
        .pages-list {
          padding-top: 1rem;
          li {
            list-style: none;
            font-size: 18px;
            padding-bottom: 1rem;
            .mail-icon {
              padding-right: 1rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 1800px) {
  .footer {
    width: 100%;
    height: 100%;
    background-image: url("../assets/footer.png");
    .footer-container {
      width: 95%;
      .pen-details {
        // width: 30%;
        width: 40%;

        display: inline-block;
        height: 100%;
        .pen-header {
          font-size: 32px;
          font-weight: 900;
        }
        .pen-title {
          padding-bottom: 15px;
          font-size: 18px;
        }
        .pen-description {
          width: calc(100% - 10px);
          font-size: 16px;
        }
        .social-media-hendles {
          padding-top: 1rem;
          .pen-social-media {
            cursor: pointer;
            list-style: none;
            display: inline-block;
            svg {
              width: 50px;
            }
          }
        }
      }
      .pen-site-details {
        width: 25%;
        display: inline-block;
        height: 100%;
        vertical-align: top;
        .site-detail {
          font-size: 28px;
          font-weight: bold;
        }
        .pages-list {
          padding-top: 1rem;
          li {
            list-style: none;
            font-size: 18px;
            padding-bottom: 0.4em;
            color: #ffeaad;
            width: max-content;
          }
        }
      }
      .pen-contact-details {
        width: 35%;
        vertical-align: top;
        display: inline-block;
        height: 100%;
        .contact-header {
          font-size: 28px;
          font-weight: bold;
        }
        .pages-list {
          padding-top: 1rem;
          li {
            list-style: none;
            font-size: 16px;
            padding-bottom: 1rem;
            color: white;
            .mail-icon {
              padding-right: 1rem;
            }
          }
        }
      }
    }
  }
}
