.eligibility-container {
  background-color: #0354a6;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 20px;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.eligibility-header {
  margin-bottom: 20px;
  text-align: center;
}

.eligibility-content {
  display: flex;
  align-items: center;
}

.eligibility-image {
  margin-right: 20px;
}

.eligibility-image img {
  width: 140px;
  height: auto;
}

.eligibility-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 2rem;
}

@media only screen and (min-width: 290px) and (max-width: 600px) {
  .eligibility-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 0.8rem;
    padding-top: 1rem;
  }
}
