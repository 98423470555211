.App {
  height: auto;
  width: 100%;
}

.section_footer {
  height: 60vh;
}

.section_service {
  height: auto;
}
.main-container {
  .section {
    height: auto;
    position: relative;
    overflow: hidden;

    .scrollable-content {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 20px;
    }
  }
}

@media only screen and (min-width: 290px) and (max-width: 600px) {
  .section {
    height: auto !important;
  }
  .section_footer {
    height: 100%;
  }

  .section_service {
    height: auto;
  }
}
