.process-container {
  background-color: #0354a6;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  justify-items: center;
  padding-top: 1rem;
}

.image-grid img {
  width: 100%;
  height: auto;
}
.image-solo {
  text-align: center;
}
.image-solo img {
  width: 30%;
  height: auto;
  margin: auto;
  text-align: center;
}

@media only screen and (min-width: 290px) and (max-width: 600px) {
  .process-container {
    height: auto;
    padding: 20px 0;
  }

  h1 {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    width: 100%;
  }

  .image-grid img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .image-solo {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .image-solo img {
    width: 42%;
    max-width: 300px;
    height: auto;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .process-container {
    height: max-content;
    padding: 4rem 0;
  }
}
