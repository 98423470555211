// Arrow icon custom changes

.custom-select {
  appearance: none;
}
// .loading-layer {
//   visibility: visible;
//   color: red;
//   height: 100%;
// }
.submit_button {
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: transform 0.2s ease-in-out;
}
.submit_button:active {
  transform: scale(0.95);
}
.submit_button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #ffffff29;
  transition: width 1s linear; /* Adjust the transition duration as needed */
  z-index: 2;
}

.submit_button.loading::before {
  width: 100%;
  z-index: 3;
}
.submit_button span {
  position: relative;
  z-index: 4;
}
.career_main_component_div {
  height: 100vh;
  width: 100%;
  padding-top: 10vh;

  .career_joinus_container {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;

    .career_main_left_div {
      width: 55%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;

      .career_title_div {
        // position: bsolute;
        height: 15%;
        width: 100%;
        font-size: 2.2rem;
        font-weight: 600;
        top: 0;
        display: flex;
        padding-left: 5rem;
        padding-top: 1rem;
      }

      .career_open_positions_div {
        height: 48%;
        width: 95%;
        border-radius: 0 12rem 12rem 0;
        background-color: #0556a6;
        display: flex;
        justify-content: flex-end;

        .positions_table {
          width: 80%;
          height: 100%;

          .positions_tabel_title {
            height: 30%;
            width: 100%;
            font-size: 1.4rem;
            font-weight: 500;
            color: white;
            display: flex;
            justify-content: start;
            align-items: center;
          }

          .positions_table_data_div {
            height: 65%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .positions_name_div {
              width: 50%;
              height: 15%;
              font-size: 1rem;
              font-weight: 400;
              color: white;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
            }
          }
        }
      }
    }

    .career_main_right_div {
      width: 45%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      position: relative;

      .career_decor_div {
        // width: 80%;
        width: 85%;
        background-color: #0556a6;
      }

      .career_main_image_div {
        // position: absolute;
        // height: 23rem;
        // width: 23rem;
        // padding: 1rem;
        // right: 17%;
        // top: 10%;
        // border-radius: 10px;
        // background-color: white;
        position: absolute;
        height: fit-content;
        min-height: 27%;
        width: 98%;
        border-radius: 30px;
        padding: 1rem;
        top: 33%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.77);
        right: 10%;
        .career_main_image {
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
      }
    }
    .career_form_div {
      position: absolute;
      height: 35%;
      width: 90%;
      bottom: 2%;

      .career_form_title {
        height: 25%;
        width: 100%;
        color: #656262;
        font-size: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        padding-left: 5%;
      }

      .career_form_main_div {
        height: 75%;
        width: 100%;
        position: relative;

        .form_main_component {
          height: 100%;
          width: 100%;
          background-color: white;
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
          display: flex;
          justify-content: space-around;
          align-items: center;

          .inputbox_and_label_div {
            height: 70%;
            width: 20%;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-top: 0.3rem;
            position: relative;

            .label_div {
              height: 20%;
              width: 100%;
              font-size: 0.9rem;
              padding-left: 0.6rem;
              font-weight: 500;
              margin-bottom: 0.5rem;
            }
            .input_div {
              height: 80%;
              width: 100%;
              border: none !important;
              position: relative;

              .main_input_div {
                background: #eeeeee 0% 0% no-repeat padding-box;
                box-shadow: inset 0px 3px 6px #00000029;
                border-radius: 2rem;
                border: none !important;
                height: 60%;
                width: 100%;
                padding-left: 0.7rem;

                // &.position {
                //   background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNiYmIiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPg==) !important;
                //   background-repeat: no-repeat !important;
                //   background-position-x: 97% !important;
                //   background-position-y: 50% !important;
                //   -webkit-appearance: none !important;
                //   -moz-appearance: none !important;
                //   -ms-appearance: none !important;
                //   -o-appearance: none !important;
                //   appearance: none !important;
                // }
              }
              .main_input_div_file_upload {
                display: none;
              }
              .upload-icon-label {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 60%;
                cursor: pointer;
                background: #eeeeee 0% 0% no-repeat padding-box;
                box-shadow: inset 0px 3px 6px #00000029;
                border-radius: 2rem;
                display: flex;
                flex-direction: row-reverse;

                .file-name {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                }
              }
              .upload-icon {
                font-size: 2rem;
                color: #666;
                margin-right: 0.5rem;
                height: 100%;
              }
              .main_input_div_file_upload + label {
                font-size: 1rem;
                padding: 0.5rem;
                background-color: #eee;
                border-radius: 0.25rem;
                cursor: pointer;
                &:hover {
                  background-color: #ddd;
                }
              }

              .main_input_div > fieldset {
                border: none !important;
                background-color: none !important;
                box-shadow: none !important;
              }

              .main_input_div > div:focus {
                background-color: unset !important;
              }

              .main_input_div > div::after:focus {
                background-color: none !important;
              }
              .main_input_div:focus {
                outline: none;
                border: 2px solid #7b7b7b;
              }
            }
          }

          .career_inputbox_submit_button {
            width: 13%;
            height: 90%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 2.9em;

            .submit_button {
              height: 55%;
              width: 100%;
              border: none;
              background-color: #0354a6;
              color: white;
              border-radius: 2rem;
              font-size: 1rem;
              font-weight: 500;
              margin-top: 3rem;
            }
          }
        }
      }
    }
  }
  .job-details {
    .job-title {
      font-weight: 600;
    }
    .job_experience {
      font-size: 20px;
      padding-bottom: 14px;
    }
    .job_description {
      font-size: 20px;
    }
  }
}

// .positions_name_div.selected{
//   width: max-content;
//   position: relative;
//   &:before {
//     content: "";
//     width: inherit;
//     height: 2px;
//     background-color: red;
//     position: absolute;
//     top: 35px;
//     border-radius: 10px;
//   }
// }
.positions_name_div.selected {
  width: max-content;
  text-decoration: underline;
}

.alertmessage-career.red {
  color: red;
  position: absolute;
  left: 39%;
  bottom: 4%;
}

.alertmessage-career.green {
  color: green;
  position: absolute;
  left: 39%;
  bottom: 4%;
}
.alertmessage-career {
  position: absolute;
}
@media screen and (min-width: 1600px) {
  

    .career_joinus_container {
      height: 100%;
      width: 100%;
      display: flex;
      position: relative;

      .career_main_left_div {
        width: 55%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        .career_title_div {
          // position: absolute;
          height: 15%;
          width: 100%;
          font-size: 3rem;
          font-weight: 600;
          top: 0;
          display: flex;
          padding-left: 5rem;
          padding-top: 1rem;
        }

        .career_open_positions_div {
          height: 48%;
          width: 95%;
          border-radius: 0 12rem 12rem 0;
          background-color: #0556a6;
          display: flex;
          justify-content: flex-end;

          .positions_table {
            width: 80%;
            height: 100%;

            .positions_tabel_title {
              height: 30%;
              width: 100%;
              font-size: 1.8rem;
              font-weight: 500;
              color: white;
              display: flex;
              justify-content: start;
              align-items: center;
            }

            .positions_table_data_div {
              height: 65%;
              width: 100%;
              display: flex;
              flex-wrap: wrap;

              .positions_name_div {
                width: 50%;
                height: 15%;
                font-size: 1.2rem;
                font-weight: 400;
                color: white;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }
            }
          }
        }
      }

      .career_main_right_div {
        width: 45%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        position: relative;

        .career_decor_div {
          // width: 50%;
          width: 85%;
          background-color: #0556a6;
        }

        .career_main_image_div {
          position: absolute;
          height: fit-content;
          min-height: 27%;
          width: 98%;
          border-radius: 30px;
          padding: 1rem;
          top: 314px;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: white;
          box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.77);
          right: 10%;
          .career_main_image {
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }
        }
      }
      .career_form_div {
        position: absolute;
        height: 35%;
        width: 90%;
        bottom: 2%;

        .career_form_title {
          height: 25%;
          width: 100%;
          color: #656262;
          font-size: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-weight: 500;
          padding-left: 5%;
        }

        .career_form_main_div {
          height: 75%;
          width: 100%;
          background-color: white;
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
          display: flex;
          justify-content: space-around;
          align-items: center;

          .inputbox_and_label_div {
            height: 50% !important;
            width: 25%;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin: unset;

            .label_div {
              height: 20%;
              width: 100%;
              font-size: 0.9rem;
              padding-left: 0.6rem;
              font-weight: 500;
            }
            .input_div {
              height: 80%;
              width: 100%;
              border: none !important;

              .main_input_div {
                background: #eeeeee 0% 0% no-repeat padding-box;
                box-shadow: inset 0px 3px 6px #00000029;
                border-radius: 2rem;
                border: none !important;
                height: 50%;
                width: 100%;
                padding-left: 0.7rem;

                .MuiSvgIcon-root {
                  font-size: 2rem !important;
                  padding-right: 0.2rem;
                }
              }
            }
          }

          .career_inputbox_submit_button {
            width: 10%;
            height: 60% !important;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 2.5rem;

            .submit_button {
              height: 35%;
              width: 100%;
              border: none;
              background-color: #0556a6;
              color: white;
              border-radius: 2rem;
              font-size: 1rem;
              font-weight: 500;
              margin-top: 2rem;
            }
          }
        }
      }
    }
  }


@media only screen and (min-width: 290px) and (max-width: 600px) {
  .career_main_component_div {
    height: unset !important;
    width: 100%;
    padding-top: 0vh;

    .career_joinus_container {
      display: block !important;

      .career_main_left_div {
        width: 100%;
        height: unset;
        display: block !important;

        .career_title_div {
          height: 10%;
          width: 100%;
          font-size: 2.2rem;
          font-weight: 600;
          top: 0;
          display: flex;
          padding-left: 2rem;
          padding-top: 1rem;
        }

        .career_open_positions_div {
          justify-content: unset;
          height: 327px;
          width: 98%;
          padding-top: 2rem;
          padding-bottom: 2rem;
          margin-bottom: 2rem;
          .positions_table {
            width: 90%;
            height: 100%;

            .positions_tabel_title {
              height: 20%;
              padding-left: 1rem;
            }

            .positions_table_data_div {
              height: 75%;
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              padding-left: 1rem;
              .positions_name_div {
                width: 50%;
                height: 30px;
                font-size: 11px;
                font-weight: 400;
                color: white;
                display: flex;
                justify-content: start;
                align-items: center;
              }
            }
          }
        }
      }

      .career_main_right_div {
        width: 100%;
        height: 445px;
        display: block !important;
        justify-content: flex-end;
        position: relative;

        .career_decor_div {
          width: 100%;
          background-color: #0556a6;
          height: 445px;
        }

        .career_main_image_div {
          position: absolute;
          height: 20rem;
          width: 20rem;
          border-radius: 30px;
          padding: 1rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: white;
          box-shadow: 10px 10px 18px 0px rgb(0 0 0 / 77%);

          .career_main_image {
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }
        }
      }
      .career_form_div {
        position: unset;
        // height: 445px;
        height: 525px;
        width: 100%;

        .career_form_title {
          font-size: 18px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-weight: 500;
          padding-left: 2rem;
        }

        .career_form_main_div {
          height: 75%;
          width: 100%;
          background-color: white;
          box-shadow: unset;
          display: block;

          .form_main_component {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 1rem;

            .inputbox_and_label_div {
              height: 80%;
              width: 80%;
              padding-left: 0.5rem;
              padding-right: 0.5rem;
              margin-top: 0.3rem;
              position: relative;

              .label_div {
                height: 20%;
                width: 100%;
                font-size: 0.9rem;
                padding-left: 0.6rem;
                font-weight: 500;
                margin-bottom: 0.5rem;
              }
              .input_div {
                height: 100%;
                width: 100%;
                border: none !important;

                .main_input_div {
                  background: #eeeeee 0% 0% no-repeat padding-box;
                  box-shadow: inset 0px 3px 6px #00000029;
                  border-radius: 2rem;
                  border: none !important;
                  height: 2.3rem;
                  width: 100%;
                  padding-left: 0.7rem;
                  position: relative;
                }

                .main_input_div > fieldset {
                  border: none !important;
                  background-color: none !important;
                  box-shadow: none !important;
                }

                .main_input_div > div:focus {
                  background-color: unset !important;
                }

                .main_input_div > div::after:focus {
                  background-color: none !important;
                }
                .main_input_div:focus {
                  outline: none;
                  border: 2px solid #7b7b7b;
                }
              }
            }

            .career_inputbox_submit_button {
              width: 60%;
              height: 80%;
              display: flex;
              margin: auto;

              .submit_button {
                height: 70%;
                width: 100%;
                border: none;
                background-color: #0556a6;
                color: white;
                border-radius: 2rem;
                font-size: 1rem;
                font-weight: 500;
                margin-top: unset;
              }
            }
          }
        }
      }
    }

    .job-details {
      .job-title {
        font-weight: 600;
      }
      .job_experience {
        font-size: 16px;
      }
      .job_description {
        font-size: 16px;
      }
    }
  }
  .alertmessage-career.red {
    color: red;
    position: absolute;
    left: 25%;
    bottom: 4%;
  }

  .alertmessage-career.green {
    color: green;
    position: absolute;
    left: 25%;
    bottom: 4%;
  }
  .alertmessage-career {
    position: absolute;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .career_main_component_div {
    // height: 100vh;
    // width: 100%;
    // padding-top: 10vh;
    height: unset;
    width: 100%;
    padding-top: unset;
    .career_joinus_container {
      // height: 100%;
      height: unset;
      width: 100%;
      display: flex;
      position: relative;

      .career_main_left_div {
        width: 55%;
        // height: 100%;
        height: 600px;
        position: relative;
        display: flex;
        flex-direction: column;

        .career_title_div {
          // position: absolute;
          height: 15%;
          width: 100%;
          font-size: 3rem;
          font-weight: 600;
          top: 0;
          display: flex;
          padding-left: 5rem;
          padding-top: 1rem;
        }

        .career_open_positions_div {
          height: 40%;
          width: 100%;
          border-radius: 0 12rem 12rem 0;
          background-color: #0556a6;
          display: flex;
          justify-content: flex-end;

          .positions_table {
            width: 100%;
            height: 100%;

            .positions_tabel_title {
              height: 20%;
              width: 100%;
              font-size: 1.8rem;
              font-weight: 500;
              color: white;
              display: flex;
              padding-left: 1rem;
              align-items: center;
            }

            .positions_table_data_div {
              height: 65%;
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              padding-left: 1rem;
              .positions_name_div {
                width: 50%;
                height: 23%;
                font-size: 13px;
                font-weight: 400;
                color: white;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }
            }
          }
        }
      }

      .career_main_right_div {
        width: 45%;
        // height: 100%;
        height: 600px;
        display: flex;
        justify-content: flex-end;
        position: relative;

        .career_decor_div {
          width: 50%;
          background-color: #0556a6;
        }

        .career_main_image_div {
          position: absolute;
          height: fit-content;
          min-height: 27%;
          width: 98%;
          border-radius: 30px;
          padding: 1rem;
          top: 33%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: white;
          box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.77);
          right: 10%;
          .career_main_image {
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }
        }
      }
      .career_form_div {
        position: absolute;
        height: 35%;
        width: 95%;
        bottom: 2%;

        .career_form_title {
          height: 25%;
          width: 100%;
          color: #656262;
          font-size: 0.8rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-weight: 500;
          padding-left: unset;
        }

        .career_form_main_div {
          height: 50%;
          width: 100%;
          background-color: white;
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
          display: flex;
          justify-content: space-around;
          align-items: center;

          .inputbox_and_label_div {
            height: 50%;
            width: 25%;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin: unset;

            .label_div {
              height: 20%;
              width: 100%;
              font-size: 0.9rem;
              padding-left: 0.6rem;
              font-weight: 500;
            }
            .input_div {
              height: 80%;
              width: 100%;
              border: none !important;

              .main_input_div {
                background: #eeeeee 0% 0% no-repeat padding-box;
                box-shadow: inset 0px 3px 6px #00000029;
                border-radius: 2rem;
                border: none !important;
                height: 50%;
                width: 100%;
                padding-left: 0.7rem;
              }
            }
          }

          .career_inputbox_submit_button {
            width: 10%;
            height: 100% !important;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 2.5rem !important;

            .submit_button {
              height: 45%;
              width: 100%;
              border: none;
              background-color: #0556a6;
              color: white;
              border-radius: 2rem;
              font-size: 1rem;
              font-weight: 500;
              margin-top: 1rem;
            }
          }
        }
      }
    }
    .job-details {
      .job-title {
        font-size: 27px;
      }
      .job_experience {
        font-size: 16px;
      }
      .job_description {
        font-size: 13px;
      }
    }
  }
}
