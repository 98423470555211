@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap");

.services {
  background-image: url("../assets/services.png");
  width: 100%;
  position: relative;
  height: unset;
  padding-bottom: 4rem;
  color: white;
  .service-container {
    padding-top: 20vh;
    .services-heading {
      padding-left: 10vw;
    }
    .services-description {
      width: 80%;
      text-align: left;
      font-size: 28px;
      padding-left: 10vw;
      // font-family: "Source Sans Pro", sans-serif !important;
    }
    .service-carousel {
      padding-top: 3rem;
      text-align: center;
      position: relative;
    }
    .service-carousel-mobile {
      display: none;
    }
    .service-carousel-tab {
      display: none;
    }
  }
  .services-image {
    .images-services {
      width: 100%;
      height: 200px;
      border-radius: 20px 20px 0 0;
      background: #f2f2f2 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      svg {
        width: 90px;
      }
    }
  }
  .service-descriptions {
    font-size: 12px;
    text-align: center !important;
    width: calc(100% - 50px);
    margin: auto;
    line-height: 24px;
    // font-family: "Raleway", sans-serif;
  }
  .service-title {
    padding-bottom: 5px;
    padding-top: 1.5rem;
    font-weight: 600;
    // font-size: 18px;
    font-size: 15px;
  }

  .swiper-slide {
    // width: 20% !important;
    // min-height: 450px;
    min-height: 585px;
    background-color: white;
    border-radius: 20px;
    color: black;
  }
  //   .swiper-backface-hidden .swiper-slide {
  //     transform: translateZ(0);
  //     backface-visibility: hidden;
  //     width: 300px !important;
  // }
  .swiper-horizontal {
    touch-action: pan-y;
    // width: 85%;
    position: initial;
    width: 80%;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 2rem;
    left: auto;
    color: unset;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 2rem;
    color: unset;
  }
  .service-carousel-mobile {
    display: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .services {
    // background-color: black;
    background-image: url("../assets/services.png");
    width: 100%;
    // height: 100vh;
    height: unset;
    padding-bottom: 4rem;
    color: white;
    .service-container {
      padding-top: 20vh;
      padding-left: unset;
      .services-heading {
        padding-left: 5rem;
      }
      .services-description {
        width: 80%;
        text-align: left;
        font-size: 28px;
        padding-left: 5rem;
        // font-family: "Source Sans Pro", sans-serif !important;
      }
      .service-carousel {
        padding-top: 3rem;
        text-align: center;
        position: relative;
        width: calc(100% - 5rem);
        margin: auto;
      }
      .service-carousel-mobile {
        display: none;
      }
      .service-carousel-tab {
        display: none;
      }
    }
    .services-image {
      .images-services {
        width: 100%;
        height: 200px;
        border-radius: 20px 20px 0 0;
        background: #f2f2f2 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        svg {
          width: 90px;
        }
      }
    }
    .service-descriptions {
      font-size: 12px;
      text-align: justify;
      width: calc(100% - 50px);
      margin: auto;
      line-height: 20px;
      // font-family: "Raleway", sans-serif;
    }
    .service-title {
      padding-bottom: 5px;
      padding-top: 1.5rem;
      font-weight: 600;
      font-size: 18px;
      // font-family: "Playfair Display", serif !important;
    }

    .swiper-slide {
      // width: 20% !important;
      // min-height: 450px;
      min-height: 555px;
      background-color: white;
      border-radius: 20px;
      color: black;
    }

    .swiper-horizontal {
      touch-action: pan-y;
      position: initial;
      width: 95%;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: 0rem;
      left: auto;
      color: unset;
    }
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      left: 0rem;
      color: unset;
    }
    .service-carousel-mobile {
      display: none;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .services {
    height: unset;
    padding-bottom: 4rem;
    position: relative;
    .service-container {
      // padding-top: 5rem;
      padding-top: 8rem;
      padding-left: 2rem;
      .services-description {
        width: 90%;
        font-size: 18px;
      }
      .service-carousel {
        display: none;
      }
      .service-carousel-mobile {
        display: none;
      }
      .service-carousel-tab {
        display: block !important;
        padding-top: 4rem;
        position: relative;
        width: calc(100% - 40px);
      }
    }
    .services-image {
      .images-services {
        width: 100%;
        height: 200px;
        border-radius: 20px 20px 0 0;
        text-align: center;
      }
    }
    .service-descriptions {
      width: calc(100% - 10px);
    }
    .service-title {
      text-align: center;
    }
    .swiper-slide {
      // width: 20% !important;
      min-height: 500px !important;
      background-color: white;
      border-radius: 20px;
      color: black;
    }
    .swiper-horizontal {
      width: 100%;
      margin: auto !important;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: -2rem;
      left: auto;
    }
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      left: -2rem;
    }
  }
}

@media only screen and (min-width: 290px) and (max-width: 600px) {
  .services {
    // background-color: black;
    background-image: url("../assets/services.png");
    width: 100%;
    height: unset;
    position: relative;
    color: white;
    .service-container {
      padding-top: unset;
      padding-left: unset;
      // padding: 2rem 2rem;
      padding: 4rem 2rem;

      .services-description {
        width: 100%;
        font-size: 14px;
      }
      .service-carousel {
        padding-top: 3rem;
        text-align: center;
        position: relative;
      }
    }
    .services-image {
      .images-services {
        width: 100%;
        height: 200px;
        border-radius: 20px 20px 0 0;
        background: #f2f2f2 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        text-align: center;
        svg {
          width: 90px;
        }
      }
    }
    .service-descriptions {
      font-size: 11px !important;
      // text-align: justify;
      width: calc(100% - 50px);
      margin: auto;
      line-height: 15px;
      // font-family: "Raleway", sans-serif;
    }
    .service-title {
      padding-bottom: 5px;
      padding-top: 1.5rem;
      font-weight: 600;
      font-size: 15px;
      // font-family: "Playfair Display", serif !important;
    }

    .swiper-slide {
      min-height: 450px !important;
      height: 450px !important;
      background-color: white;
      border-radius: 20px;
      color: black;
    }
    .swiper-horizontal {
      touch-action: pan-y;
      // width: 85%;
      position: initial;
      width: 80%;
    }
  }

  .service-carousel {
    display: none;
  }
  .service-carousel-mobile {
    display: block !important;
    padding-top: 2rem;
    .swiper-horizontal {
      touch-action: pan-y;
      // width: 85%;
      position: initial;
      width: 80%;
    }
    .service-descriptions {
      font-size: 13px;
      text-align: left;
      width: calc(100% - 50px);
      margin: auto;
      // font-family: "Raleway", sans-serif;
    }
    .service-title {
      padding-bottom: 5px;
      padding-top: 1.5rem;
      text-align: center;
      font-size: 18px;
      // font-family: unset !important;
    }
    .swiper-button-prev,
    .services .swiper-rtl .swiper-button-next {
      left: 1rem;
      position: absolute;
      z-index: 0;
    }
    .swiper-button-next,
    .services .swiper-rtl .swiper-button-prev {
      right: 1rem;
      left: auto;
      position: absolute;
      z-index: 0;
    }
  }
}

@media screen and (min-width: 290px) and (max-width: 700px) and (orientation: landscape) {
  .services .service-container {
    padding-top: 4rem;
    padding-left: 2rem;
  }
}
