@import "../colorvariables.scss";

.join_us_main_component_div {
  height: 100vh;
  padding-top: 10vh;
  // background-color: $white-color;
  background-image: url("../assets/JoinUsBg.png");

  position: relative;
  .landingpage_joinus_container {
    height: 100%;
    width: 100%;
    .joinus_decoration_div {
      width: 5%;
      height: 100%;
      background-color: $primary-color;
    }

    .joinus_content_div {
      width: 95%;
      height: 100%;
      display: flex;

      .joinus_content_leftdiv {
        width: 100%;
        height: 100%;

        .join_us_content_div {
          height: 20%;
          width: 100%;

          .joinus_title_div {
            height: 100%;
            width: 20%;
            font-size: 2rem;
            font-weight: 600;
            letter-spacing: 2px;
          }

          .join_us_text {
            height: 80%;
            width: 70%;
            font-size: 0.8rem;
            letter-spacing: 2px;
            background-color: $primary-color;
            border-radius: 3rem 0 0 3rem;
            color: $white-color;
          }
        }

        .joinus_left_component_info_div {
          height: 80%;
          width: 100%;
          position: relative;

          .joinus_circle_deco_div {
            position: absolute;
            height: 120%;
            width: 5%;
            background-color: $primary-color;
            right: 0;
            top: -5%;
          }

          .joinus_main_circle_div {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;

            .joinus_main_circle {
              height: 26rem;
              width: 26rem;
              padding: 1rem;
              background-color: $white-color;
              border-radius: 50%;
              box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
              position: relative;

              .join_us_text_inside_circle {
                position: absolute;
                width: 80%;
                top: 30%;
                left: 10%;
                height: 50%;

                .title_div {
                  height: 20%;
                  width: 100%;
                  font-size: 1.7rem;
                  font-weight: 500;
                  color: $white-color;
                  text-shadow: 2px 2px #090909;
                }

                .text_div {
                  height: 80%;
                  width: 90%;
                  font-size: 1.1rem;
                  font-weight: 400;
                  padding-left: 10%;
                  text-shadow: 1px 1px #000000;
                  color: $white-color;
                }
              }

              .joinus_main_image {
                height: 100%;
                width: 100%;
                border-radius: 50%;
              }

              .joinus_sub_circleone {
                position: absolute;
                height: 7rem;
                width: 7rem;
                top: -2%;
                left: 1%;
                padding: 0.5rem;
                background-color: $white-color;
                border-radius: 50%;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                transition-duration: 500ms;
                &:hover {
                  transform: scale(1.3);
                  transition-duration: 500ms;
                }

                .image_container_joinus {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #87cc5a;
                  cursor: pointer;

                  .joinus_sub_image {
                    height: 40%;
                    width: 40%;
                  }
                }
              }
              .joinus_sub_circletwo {
                position: absolute;
                height: 7rem;
                width: 7rem;
                top: 32%;
                background-color: $white-color;
                border-radius: 50%;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                padding: 0.5rem;
                left: -15%;
                transition-duration: 500ms;
                &:hover {
                  transform: scale(1.3);
                  transition-duration: 500ms;
                }
                .image_container_joinus {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;

                  background-color: #bfce18;

                  .joinus_sub_image {
                    height: 40%;
                    width: 40%;
                  }
                }
              }
              .joinus_sub_circlethree {
                position: absolute;
                height: 7rem;
                width: 7rem;
                top: 68%;
                background-color: $white-color;
                border-radius: 50%;
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                padding: 0.5rem;
                left: -5%;
                transition-duration: 500ms;
                &:hover {
                  transform: scale(1.3);
                  transition-duration: 500ms;
                }
                .image_container_joinus {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #faac62;
                  cursor: pointer;

                  .joinus_sub_image {
                    height: 40%;
                    width: 40%;
                  }
                }
              }
              .joinus_sub_circlefour {
                position: absolute;
                height: 7rem;
                width: 7rem;
                top: -2%;
                right: 1%;
                padding: 0.5rem;
                background-color: $white-color;
                border-radius: 50%;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                transition-duration: 500ms;
                &:hover {
                  transform: scale(1.3);
                  transition-duration: 500ms;
                }
                .image_container_joinus {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #c38f5f;
                  cursor: pointer;

                  .joinus_sub_image {
                    height: 40%;
                    width: 40%;
                  }
                }
              }
              .joinus_sub_circlefive {
                position: absolute;
                height: 7rem;
                width: 7rem;
                top: 32%;
                background-color: $white-color;
                border-radius: 50%;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                padding: 0.5rem;
                right: -15%;
                transition-duration: 500ms;
                &:hover {
                  transform: scale(1.3);
                  transition-duration: 500ms;
                }
                .image_container_joinus {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #59a0be;
                  cursor: pointer;

                  .joinus_sub_image {
                    height: 40%;
                    width: 40%;
                  }
                }
              }
              .joinus_sub_circlesix {
                position: absolute;
                height: 7rem;
                width: 7rem;
                top: 68%;
                background-color: $white-color;
                border-radius: 50%;
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                padding: 0.5rem;
                right: -5%;
                transition-duration: 500ms;
                &:hover {
                  transform: scale(1.3);
                  transition-duration: 500ms;
                }
                .image_container_joinus {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #93cbff;
                  cursor: pointer;

                  .joinus_sub_image {
                    height: 40%;
                    width: 40%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .join_us_main_component_div {
    .landingpage_joinus_container {
      .joinus_content_div {
        .joinus_content_leftdiv {
          .join_us_content_div {
            .joinus_title_div {
              // font-size: 3.7rem;
              font-size: 2.5rem;
            }

            .join_us_text {
              font-size: 1.2rem;

              border-radius: 4rem 0 0 4rem;
            }
          }

          .joinus_left_component_info_div {
            .joinus_circle_deco_div {
              position: absolute;
              height: 120%;
              width: 5%;
              right: 0;
              top: -5%;
            }

            .joinus_main_circle_div {
              height: 100%;
              width: 97%;
              display: flex;
              justify-content: center;

              .joinus_main_circle {
                height: 500px;
                width: 513px;
                margin-top: 1rem;
                padding: 1rem;
                background-color: white;
                border-radius: 50%;
                position: relative;

                .join_us_text_inside_circle {
                  position: absolute;
                  width: 80%;
                  top: 30%;
                  left: 10%;
                  height: 50%;

                  .title_div {
                    height: 20%;
                    width: 100%;
                    font-size: 26px;
                    font-weight: 500;
                  }

                  .text_div {
                    height: 80%;
                    width: 90%;
                    font-size: 1.1rem;
                    font-weight: 400;
                    margin: auto;
                    padding-left: 0% !important;
                  }
                }

                .joinus_main_image {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                }

                .joinus_sub_circleone {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: -2%;
                  left: 1%;
                  padding: 0.5rem;
                  border-radius: 50%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circletwo {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 32%;
                  border-radius: 50%;
                  padding: 0.5rem;
                  left: -15%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlethree {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 68%;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                  padding: 0.5rem;
                  left: -5%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlefour {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: -2%;
                  right: 1%;
                  padding: 0.5rem;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlefive {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 32%;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                  padding: 0.5rem;
                  right: -15%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlesix {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 68%;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                  padding: 0.5rem;
                  right: -5%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 290px) and (max-width: 600px) {
  .join_us_main_component_div {
    height: unset;
    padding-top: unset;
    padding-top: 3rem;
    padding-bottom: 3rem;
    .landingpage_joinus_container {
      height: 100%;
      width: 100%;
      .joinus_decoration_div {
        display: none;
      }

      .joinus_content_div {
        width: 95%;
        height: 100%;
        display: flex;

        .joinus_content_leftdiv {
          width: 100%;
          height: 100%;

          .join_us_content_div {
            height: 20%;
            width: 100%;
            display: block !important;

            .joinus_title_div {
              width: 100%;
              padding-left: unset !important;
              display: block !important;
              height: 40%;
              padding-bottom: 2rem;
            }

            .join_us_text {
              height: 80%;
              width: 70%;
              font-size: 0.8rem;
              font-weight: 600;
              letter-spacing: 2px;
              background-color: #0556a6;
              border-radius: 3rem 0 0 3rem;
              color: white;
            }
          }

          .joinus_left_component_info_div {
            height: 80%;
            width: 100%;
            position: relative;

            .joinus_circle_deco_div {
              display: none;
            }

            .joinus_main_circle_div {
              height: 85%;
              width: 100%;
              display: flex;
              justify-content: center;
              margin: auto;

              .joinus_main_circle {
                height: 18rem;
                width: 18rem;
                padding: 0.5rem;
                margin-top: 2rem;
                .join_us_text_inside_circle {
                  width: 80%;
                  top: 30%;
                  left: 10%;
                  height: 50%;

                  .title_div {
                    height: 20%;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 500;
                    color: #e3e3e3;
                    text-shadow: 2px 2px #090909;
                  }

                  .text_div {
                    // height: 80%;
                    // width: 100%;
                    // font-size: 12px;
                    // font-weight: 400;
                    // text-shadow: 1px 1px #000000;
                    // color: #e3e3e3;
                    height: 80%;
                    width: 85%;
                    font-size: 14px;
                    font-weight: 400;
                    text-shadow: 1px 1px #000000;
                    color: #e3e3e3;
                    margin: auto;
                    padding-left: 1%;
                  }
                }

                .joinus_main_image {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                }

                .joinus_sub_circleone {
                  position: absolute;
                  height: 5rem;
                  width: 5rem;
                  top: -2%;
                  left: 1%;
                  padding: 0.3rem;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

                  .image_container_joinus {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #87cc5a;
                    cursor: pointer;

                    .joinus_sub_image {
                      height: 40%;
                      width: 40%;
                    }
                  }
                }
                .joinus_sub_circletwo {
                  position: absolute;
                  height: 5rem;
                  width: 5rem;
                  top: 32%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                  padding: 0.3rem;

                  left: -15%;

                  .image_container_joinus {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    background-color: #bfce18;

                    .joinus_sub_image {
                      height: 40%;
                      width: 40%;
                    }
                  }
                }
                .joinus_sub_circlethree {
                  position: absolute;
                  height: 5rem;
                  width: 5rem;
                  top: 68%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                  padding: 0.3rem;

                  left: -5%;

                  .image_container_joinus {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #faac62;
                    cursor: pointer;

                    .joinus_sub_image {
                      height: 40%;
                      width: 40%;
                    }
                  }
                }
                .joinus_sub_circlefour {
                  position: absolute;
                  height: 5rem;
                  width: 5rem;
                  top: -2%;
                  right: 1%;
                  padding: 0.3rem;

                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

                  .image_container_joinus {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #c38f5f;
                    cursor: pointer;

                    .joinus_sub_image {
                      height: 40%;
                      width: 40%;
                    }
                  }
                }
                .joinus_sub_circlefive {
                  position: absolute;
                  height: 5rem;
                  width: 5rem;
                  top: 32%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                  padding: 0.3rem;

                  right: -15%;

                  .image_container_joinus {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #59a0be;
                    cursor: pointer;

                    .joinus_sub_image {
                      height: 40%;
                      width: 40%;
                    }
                  }
                }
                .joinus_sub_circlesix {
                  position: absolute;
                  height: 5rem;
                  width: 5rem;
                  top: 68%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                  padding: 0.3rem;

                  right: -5%;

                  .image_container_joinus {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #93cbff;
                    cursor: pointer;

                    .joinus_sub_image {
                      height: 40%;
                      width: 40%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .join_us_main_component_div
    .landingpage_joinus_container
    .joinus_content_div
    .joinus_content_leftdiv
    .join_us_content_div
    .join_us_text {
    height: 100%;
    width: 90%;
    font-size: 0.8rem;
    font-weight: 400;
    float: right;
    padding: 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .join_us_main_component_div {
    height: unset;
    .landingpage_joinus_container {
      .joinus_content_div {
        .joinus_content_leftdiv {
          .join_us_content_div {
            align-items: center;
            .joinus_title_div {
              font-size: 40px;
              width: 30%;
              padding-left: unset !important;
            }

            .join_us_text {
              font-size: 14px;
              border-radius: 4rem 0 0 4rem;
              font-weight: 400;
              padding: 1rem;
            }
          }

          .joinus_left_component_info_div {
            padding-bottom: 3rem;
            .joinus_circle_deco_div {
              position: absolute;
              height: 120%;
              width: 5%;
              background-color: #0055a5;
              right: 0;
              top: -5%;
            }

            .joinus_main_circle_div {
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              // padding-right: 7%;

              .joinus_main_circle {
                height: 30rem;
                width: 30rem;
                padding: 1rem;
                background-color: white;
                border-radius: 50%;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                position: relative;
                margin-top: 5rem;

                .join_us_text_inside_circle {
                  position: absolute;
                  width: 80%;
                  top: 30%;
                  left: 10%;
                  height: 50%;

                  .title_div {
                    height: 20%;
                    width: 100%;
                    font-size: 1.9rem;
                    font-weight: 500;
                    color: #e3e3e3;
                    text-shadow: 2px 2px #090909;
                  }

                  .text_div {
                    height: 80%;
                    width: 90%;
                    font-size: 1.1rem;
                    font-weight: 400;
                    text-shadow: 1px 1px #000000;
                    color: #e3e3e3;
                    padding-left: 10%;
                  }
                }

                .joinus_main_image {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                }

                .joinus_sub_circleone {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: -2%;
                  left: 1%;
                  padding: 0.5rem;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circletwo {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 32%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                  padding: 0.5rem;
                  left: -15%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlethree {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 68%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                  padding: 0.5rem;
                  left: -5%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlefour {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: -2%;
                  right: 1%;
                  padding: 0.5rem;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlefive {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 32%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                  padding: 0.5rem;
                  right: -15%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlesix {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 68%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                  padding: 0.5rem;
                  right: -5%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .join_us_main_component_div {
    height: unset;
    .landingpage_joinus_container {
      .joinus_content_div {
        .joinus_content_leftdiv {
          .join_us_content_div {
            align-items: center;
            .joinus_title_div {
              font-size: 40px;
              width: 30%;
              padding-left: unset !important;
            }

            .join_us_text {
              font-size: 14px;
              border-radius: 4rem 0 0 4rem;
              font-weight: 400;
              padding: 1rem;
            }
          }

          .joinus_left_component_info_div {
            padding-bottom: 3rem;
            .joinus_circle_deco_div {
              position: absolute;
              height: 120%;
              width: 5%;
              background-color: #0055a5;
              right: 0;
              top: -5%;
            }

            .joinus_main_circle_div {
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              // padding-right: 7%;

              .joinus_main_circle {
                height: 31.5rem;
                width: 31.5rem;
                padding: 1rem;
                background-color: white;
                border-radius: 50%;
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                position: relative;
                margin-top: 1rem;

                .join_us_text_inside_circle {
                  position: absolute;
                  width: 80%;
                  top: 30%;
                  left: 10%;
                  height: 50%;

                  .title_div {
                    height: 20%;
                    width: 100%;
                    font-size: 1.9rem;
                    font-weight: 500;
                    color: #e3e3e3;
                    text-shadow: 2px 2px #090909;
                  }

                  .text_div {
                    height: 80%;
                    width: 90%;
                    font-size: 1.1rem;
                    font-weight: 400;
                    text-shadow: 1px 1px #000000;
                    color: #e3e3e3;
                    padding-left: 10%;
                  }
                }

                .joinus_main_image {
                  height: 100%;
                  width: 100%;
                  border-radius: 50%;
                }

                .joinus_sub_circleone {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: -2%;
                  left: 1%;
                  padding: 0.5rem;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circletwo {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 32%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                  padding: 0.5rem;
                  left: -15%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlethree {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 68%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                  padding: 0.5rem;
                  left: -5%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlefour {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: -2%;
                  right: 1%;
                  padding: 0.5rem;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlefive {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 32%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                  padding: 0.5rem;
                  right: -15%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
                .joinus_sub_circlesix {
                  position: absolute;
                  height: 8rem;
                  width: 8rem;
                  top: 68%;
                  background-color: white;
                  border-radius: 50%;
                  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                  padding: 0.5rem;
                  right: -5%;

                  .image_container_joinus {
                    .joinus_sub_image {
                      height: 50%;
                      width: 50%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
